"use client";

import clsx from "clsx";
import Link from "next/link";
import React, { useEffect, useState } from "react";

import { Collapse } from "@/components/Form/Button/Collapse";
import { MinusLineIcon } from "@/components/Icon/Minus/MinusLineIcon";
import { PlusLineIcon } from "@/components/Icon/Plus/PlusLineIcon";
import { Flyout } from "@/components/Modal/Flyout";
import { SidebarSkeleton } from "@/components/Skeleton/Sidebar";
import { MenuLink } from "@/data/Menu";
import { SidebarDataCategoryInterface, SidebarDataInterface, SidebarDataLinkInterface } from "@/interfaces/Sidebar/SidebarInterface";
import { useLayout } from "@/lib/contexts/layout-context";
import { SIDEBAR_MODAL, useSideModal } from "@/lib/contexts/sidemodal-context";

interface StrapiItemAttributesInterface {
  attributes: {
    title: string;
    url?: string;
    children?: StrapiItemAttributesChildInterface;
  };
}

interface StrapiItemAttributesChildInterface extends StrapiItemAttributesInterface {
  data?: StrapiItemAttributesInterface[];
}

const Arrow = ({ collapsed }: { collapsed: boolean }) => {
  return !collapsed ? <MinusLineIcon width={20} className="float-right mt-1" /> : <PlusLineIcon width={20} className="float-right mt-1" />;
};

export const Sidebar = ({ className, links: menuLinks, categories }: { className?: string; links: MenuLink[]; categories?: MenuLink[] }) => {
  const { modal, close } = useSideModal();
  const { handleSearching } = useLayout();
  const [selectedCollapse, setSelectedCollapse] = useState<SidebarDataInterface | null>(null);
  const [collapses, setCollapses] = useState<MenuLink[]>(menuLinks);

  useEffect(() => {
    if (categories?.length) {
      const _collapses = [...collapses];

      const categoriesItem = {
        name: "Sortiment",
        links: categories,
      } as MenuLink;

      if (!collapses.find((collapse) => collapse.name === categoriesItem.name)) {
        _collapses.unshift(categoriesItem);
        setCollapses(_collapses);
      }
    }
  }, [categories]);

  const handleClick = () => {
    close();
    handleSearching(false);
  };

  return (
    <Flyout open={modal.name === SIDEBAR_MODAL && modal.isShow} onClose={close} className={className} title="Menü">
      <div>
        {collapses.length <= 0 && <SidebarSkeleton />}
        {collapses.map((collapse: SidebarDataInterface, index: number) => {
          const isLink: boolean = !collapse?.links?.length;
          const isSelected = collapse === selectedCollapse;

          return (
            <div onMouseOver={() => setSelectedCollapse(collapse)} onMouseLeave={() => setSelectedCollapse(null)} key={index}>
              <Collapse
                title={<Title collapse={collapse} selectedCollapse={selectedCollapse} />}
                customStyle={`uppercase border-b-2 border-gray-300 ${isSelected ? "category-bg-gray hover:bg-gray-200" : "bg-white hover:category-bg-gray"}`}
                customContentStyle={"bg-white"}
                onClick={() => {
                  if (isLink) {
                    close();
                    return;
                  }

                  setSelectedCollapse(isSelected ? null : collapse);
                }}
                enabled={isSelected}
                link={isLink && collapse.href}
                id={"sidebarCollapse"}
              >
                <Categories collapse={collapse} selectedCollapse={selectedCollapse} onClick={() => handleClick()} />
              </Collapse>
            </div>
          );
        })}
      </div>
    </Flyout>
  );
};

const Title = ({
  collapse,
  selectedCollapse,
}: {
  collapse: { links?: SidebarDataLinkInterface[] } & (SidebarDataCategoryInterface | SidebarDataInterface);
  selectedCollapse: SidebarDataInterface | null;
}) => {
  return (
    <>
      <span className="tracking-wide font-medium text-md">
        <span className="mr-1">{collapse.name}</span>
      </span>
      {!collapse?.links?.length ? null : <Arrow collapsed={!(selectedCollapse === collapse)} />}
    </>
  );
};

const Categories = ({
  collapse,
  selectedCollapse,
  onClick,
}: {
  collapse: SidebarDataInterface;
  selectedCollapse: SidebarDataInterface | null;
  onClick: () => void;
}) => {
  if (!collapse?.links?.length) {
    return null;
  }

  return (
    <div>
      {collapse?.links?.map((link: SidebarDataLinkInterface, key: number) => {
        return (
          <div className="border-b-2 border-gray-300" key={key}>
            {
              <Link
                href={link.href || ""}
                className={clsx("block py-2 px-4 text-md bg-white hover:category-bg-gray pl-7", {
                  underline: link.underline,
                  "category-bg-gray hover:bg-gray-200": collapse === selectedCollapse,
                })}
                onClick={onClick}
                {...(process.env.NODE_ENV !== "production" && {
                  "data-testid": `link${key}`,
                })}
              >
                {link.name}
              </Link>
            }
          </div>
        );
      })}
    </div>
  );
};
