import Link from "next/link";

import ProductFlags, { ProductFlagPosition } from "@/components/Product/components/ProductFlags";
import { ProductPrice } from "@/components/Product/components/ProductPrice";
import { PlaceholderImageString, ProductImage } from "@/components/Product/ProductImage";
import { WishlistProductIcon } from "@/components/Wishlist/WishlistProductIcon";
import { ProductPreviewType } from "@/types/global";

interface ProductCardInterface {
  product: ProductPreviewType;
  className?: string;
  onClick?: () => void;
  isDemo?: boolean;
}

export const ProductCard = ({ product, className, onClick }: ProductCardInterface) => {
  const productDetailLink = `/p/${product.handle}`;
  const productThumbnail = product.thumbnail ?? PlaceholderImageString;

  return (
    <div className={`flex flex-col ${className}`} onClick={onClick}>
      <div className="relative w-full">
        <ProductFlags product={product} position={ProductFlagPosition.topLeft} />
        <ProductFlags product={product} position={ProductFlagPosition.bottomRight} />
        <div className="px-2">
          <WishlistProductIcon product_id={product.id as string} />
        </div>
        {productDetailLink && productThumbnail && (
          <Link href={productDetailLink} className={"w-full"}>
            <div className={"absolute inset-0 bg-black opacity-[03%] z-10 pointer-events-none rounded-t-3xl"}></div>
            <ProductImage
              src={productThumbnail}
              width={200}
              height={1000}
              alt={product.title ?? ""}
              fallbackimage={PlaceholderImageString}
              className="w-[auto] h-[12.5rem] md:h-[19rem] lg:h-[20rem] mx-auto object-cover"
            />
          </Link>
        )}
        {!productDetailLink && productThumbnail && (
          <ProductImage src={productThumbnail} width={400} height={540} alt={product.title ?? ""} fallbackimage={PlaceholderImageString} />
        )}
      </div>
      <div className="pt-2 px-4 pb-4">
        <Link href={productDetailLink ?? ""} className="my-2" title={product.title}>
          <p className="min-h-[50px] md:min-h-[60px] font-bold text-kodi-blue text-md sm:text-lg md:text-lg uppercase line-clamp-2">{product.title ?? ""}</p>
        </Link>
        <div className="w-full min-h-[84px] sm:min-h-[88px] lg:min-h-[108px] flex justify-end items-center">
          <ProductPrice product={product} />
        </div>
      </div>
    </div>
  );
};
